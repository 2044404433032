import React from "react";

export const Product = (props) => {
  return (
    <div id="product">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <div className="about-text">
              <h2>Technology</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
            <div className="">
              <div className="about-text">
                <h3>Products</h3>
                <div >
                  <div>
                    <ul>
                      {props.data
                        ? props.data.Features.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                        : "loading"}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <img src="img/family.webp" className="img-responsive" alt="" width="400" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
