import React from "react";

export const Roles = (props) => {
  return (
    <div id="roles" >
      <div className="container">
        <div className="section-title">
          <h2>Open Roles</h2>
          <div className="application-instructions-container">
            <div>
              To apply, email info@pretzellproject.com with:
            </div>
            <ol className="application-instructions" type="1">
              <li>your resume</li>
              <li>the position you're applying to</li>
              <li>a few sentances on your relationship to dementia</li>
            </ol>
          </div>
        </div>
        <div className="roles-container">
          {props.data ? props.data.map((role, index) => (
            <div key={`${role.title}-${index}`} className="role-item">
              <div className="role-desc">
                <h3>{role.title}</h3>
                <p>{role.hours}</p>
                <p><strong>Description:</strong> <ul>{role.description.map(r => <li>{r}</li>)}</ul></p>
                <p><strong>Qualifications:</strong> <ul>{role.qualifications.map(r => <li>{r}</li>)}</ul></p>
                {role.majors ? <p><strong>Majors: </strong>{role.majors}</p> : <div></div> }
                <p><strong>Compensation:</strong> {role.compensation}</p>
              </div>
            </div>
          )) : "Loading roles..."}
        </div>
      </div>
    </div>
  );
};
