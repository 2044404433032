import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Product } from "./components/product";
import { Services } from "./components/roles";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Apply } from "./components/apply";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
//import SmoothScroll from "smooth-scroll";
import { Link } from 'react-scroll';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Roles } from "./components/roles";
import "./App.css";

/*
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
*/

const Home = ({ data }) => (
  <>
    <Navigation />
    <Header data={data.Header} />
    <About data={data.About} />
    <Product data={data.Product} />
    <Contact data={data.Contact} />
  </>
);

const OpenRoles = ({ data }) => (
  <>
    <Navigation />
    <Roles data={data.Roles} />
  </>
);


const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home data={landingPageData} />} />
        <Route path="/apply" element={<OpenRoles data={landingPageData} />} />
      </Routes>
    </Router>
  );
};

export default App;
