import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link } from 'react-scroll';
import { PretzelSVG } from "./pretzel";

export const Navigation = (props) => {
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {isHomePage ? (
            <>
          <Link className="hide-mobile navbar-brand page-scroll" to="/#page-top" spy={true} smooth={true} duration={500}>
            Pretzell Project
          </Link>
          <Link className="page-scroll" to="/#page-top" spy={true} smooth={true} duration={500}>
          <PretzelSVG color="#000000" width="60" height="50" />
          </Link>
          </>
          ) : (
            <>
          <RouterLink className="hide-mobile navbar-brand page-scroll" to="/">
            Pretzell Project
          </RouterLink>
          <RouterLink className="page-scroll" to="/">
           <PretzelSVG color="#000000" width="60" height="50" />
          </RouterLink>
          </>
          )}

        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          {/* Navigation content */}
          <ul className="nav navbar-nav navbar-right">
            {/* Conditional rendering based on whether the user is on the home page */}
            {isHomePage ? (
              <>
                {/* Links for scrolling within the home page */}
                <li><Link to="about" className="page-scroll" spy={true} smooth={true} duration={500}>About</Link></li>
                <li><Link to="product" className="page-scroll" spy={true} smooth={true} duration={500}>Product / Tech</Link></li>
                <li><Link to="contact" className="page-scroll" spy={true} smooth={true} duration={500}>Contact</Link></li>
              </>
            ) : (
              <>
                {/* Links for navigating back to the home page and then scrolling to the section */}
                <li><RouterLink to="/#about" className="page-scroll">About</RouterLink></li>
                <li><RouterLink to="/#product" className="page-scroll">Product / Tech</RouterLink></li>
                <li><RouterLink to="/#contact" className="page-scroll">Contact</RouterLink></li>
              </>
            )}
            {/* Apply link always navigates to a different route */}
            <li><RouterLink to="/apply" className="page-scroll">Apply</RouterLink></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
